export default function () {
  return [
    {
      key: 'userIds',
      label: 'depositor',
      type: 'user',
      selectProps: {
        clearable: true,
        multiple: true
      }
    },
    {
      key: 'status',
      type: 'monetaryStatus',
      selectProps: {
        clearable: true,
        multiple: true
      }
    },
    {
      key: 'action',
      label: "monetaryAction",
      type: 'monetaryAction',
      selectProps: {
        clearable: true,
        multiple: true
      }
    },
    {
      key: 'realType',
      label: "monetaryRealType",
      type: 'monetaryRealType',
      selectProps: {
        clearable: true,
        multiple: true
      }
    },
    {
      key: 'account',
      label: 'account'
    },
    {
      key: 'clientDetails.clientId',
      label: 'client',
      type: 'clientId',
      selectProps: {
        clearable: true,
        multiple: false
      }
    },
    {
      key: 'businessUnit',
      label: 'businessUnit',
      type: 'bu',
      selectProps: {
        clearable: true,
        multiple: false
      }
    },
    {
      key: 'orgIds',
      type: 'org',
      props: {
        clearable: true,
        multiple: true
      }
    },
    {
      key: 'maxAmount',
      label: 'maxAmount',
      type: 'number'
    },
    {
      key: 'minAmount',
      label: 'minAmount',
      type: 'number'
    },
    // {
    //   key: 'registrationTime',
    //   label: 'registrationTime',
    //   type: 'dateRange',
    //  props: {mode:"dateTime"}
    // },
    {
      type: "checkboxGroup",
      fields: [
        {
          key: 'isFtd',
          label: 'isFtd',
          type: 'checkbox'
        },
        {
          key: 'isStd',
          label: 'isStd',
          type: 'checkbox'
        },
        {
          key: 'isTest',
          label: 'isTest',
          type: 'checkbox'
        }
      ]
    }
  ];
}
