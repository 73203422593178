export default function (other) {
  other = other || {};
  return {
    id: other.id || null,
    businessUnit: other.businessUnit || null,
    orgId: other.orgId || null,
    realType: other.realType || null,
    clientDetails: other.clientDetails || null,
    type: other.type || null,
    amount: other.amount || null,
    currency: other.currency || null,
    registrationTime: other.registrationTime || null,
    paymentDetails: other.paymentDetails || null,
    wallet: other.wallet || null,
    action: other.action || null,
    status: other.status || null,
    isFtd: other.isFtd || null,
    depositorName: other.depositorName || null,
  };
}
